import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import HelpMethodsSection from "../../components/helpMethodsSection";
import ImageCaptionSection from "../../components/imageCaptionSection";
import EmphasisTextUnderlined from "../../components/emphasisTextUnderline";
import DownloadBox, {downloadBoxParams} from "../../components/downloadBox";

const CareersPage = (props) => {

  const data = useStaticQuery(graphql`
    query CareersPageQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "careers"}}) {
        frontmatter {
          imageCaptionSection {
            subTitle
            title
            imageCaptionBackground
          }
          jobOpenings {
            title
            jobDescriptionLink
          }
          careersEmail
        }
      }
    }
  `);
  const {
    imageCaptionSection: {title, subTitle, imageCaptionBackground},
    jobOpenings, careersEmail
  } = data.markdownRemark.frontmatter;

  // Remove dummy (first) entry
  const allOpenings = (jobOpenings || []).length > 1 ? jobOpenings.slice(1, jobOpenings.length) : [];
  const jobOpeningsList = allOpenings.length > 0 ? (
    <div>
      <div className="flex justify-center my-10">
        <EmphasisTextUnderlined text="Active Job Openings" className="text-8xl font-emphasis text-center"
                                strokeType="strokeSmallYellow"/>
      </div>
      <div className="w-full">
        {allOpenings.map(({title, jobDescriptionLink}, index) => (
          <DownloadBox key={index} title={title} showDownloadIcon={!!jobDescriptionLink}
                       link={jobDescriptionLink} linkLabel="Download Job Description"
                       {...downloadBoxParams[index % downloadBoxParams.length]}
          />
        ))}
      </div>
    </div>
  ) : null;

  const jobApplicationForm = allOpenings.length ? (
    <form name="careers" method="post" data-netlify="true" className="flex flex-col flex-1 p-5 md:px-32">
      <input type="hidden" name="form-name" value="careers"/>

      <p className="text-2xl md:text-4xl font-bold text-blue-400 text-center mb-20">
        PLEASE APPLY BELOW FOR THE JOB OPENINGS ABOVE
      </p>

      <label htmlFor="jobOpening" className="text-xl mb-2">
        Which Job Opening are you applying for?
      </label>
      <select
        name="jobOpening"
        className="text-xl bg-gray-200 p-4 rounded-md mb-10"
        defaultValue="" // set the default value to an empty string
        required // make the field required
      >
        <option value="" disabled hidden>
          -- Select Job Opening --
        </option>
        {allOpenings.map(({title}, index) => <option value={title} key={index}>{title}</option>)}
      </select>

      <input name="name" className="text-xl bg-gray-200 p-4 rounded-md mb-10" placeholder="Name" required/>

      <textarea name="address" rows={3} className="text-xl bg-gray-200 p-4 rounded-md mb-10" placeholder="Address"
                required/>

      <div className="flex flex-col md:flex-row">
        <input name="mobile" className="flex-1 text-xl bg-gray-200 p-4 rounded-md mb-10 md:mr-5"
               placeholder="Mobile Number"
               required/>
        <input name="email" className="flex-1 text-xl bg-gray-200 p-4 rounded-md mb-10 md:ml-5"
               placeholder="E-mail Address"
               required/>
      </div>

      <p className="text-xl mb-5">Date of Birth</p>
      <input name="dateOfBirth" className="flex-1 text-xl bg-gray-200 p-4 rounded-md mb-10"
             type="date" required/>

      <input name="lastAcademicQualifications" className="flex-1 text-xl bg-gray-200 p-4 rounded-md mb-10"
             placeholder="Last Academic Qualifications" required/>

      <input name="howDidYouHearOfRaphael" className="flex-1 text-xl bg-gray-200 p-4 rounded-md mb-10"
             placeholder="How did you first hear of Raphael?" required/>

      <p className="text-xl mb-5">Tell us about yourself in a few sentences</p>
      <textarea name="aboutYourself" rows={3} className="text-xl bg-gray-200 p-4 rounded-md mb-10"
                required/>

      <p className="text-md mb-2">Upload your Resume (Under 2MB)</p>
      <input name="uploadedResume" type="file" required
             className="text-xl bg-gray-200 p-4 rounded-md mb-10"/>

      <input type="submit" value="APPLY"
             className="text-2xl font-bold text-white bg-blue-400 hover:bg-blue-600 w-fit
                 px-20 py-4 rounded-full mx-auto"/>
    </form>
  ) : null;

  return (
    <Layout activeLink="/get-involved" pageTitle={title} pageDescription={subTitle} {...props}>
      <ImageCaptionSection title={title} subTitle={subTitle} imageAlt={`${title} - ${subTitle}`}
                           imageSrc={imageCaptionBackground}/>
      {jobOpeningsList}
      {jobApplicationForm}
      <p className="text-2xl text-center mx-5 md:mx-40 mt-20 break-words">
        If your are interested in any kind of work with us or for more information about career opportunities
        write to us at&nbsp;
        <a href={`mailto:${careersEmail}`} className="text-blue-500 hover:text-blue-800"><i>{careersEmail}</i></a>
      </p>

      <HelpMethodsSection/>
    </Layout>
  );
};

CareersPage.propTypes = {};

export default CareersPage;
